import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { IndustriesSiteDTO } from '@DTO/IndustriesSiteDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface IndustriesSiteProps {
  pageContext?: TemplatePageContextData;
}

const IndustriesSite = ({ pageContext }: IndustriesSiteProps) => {
  const industriesSite = IndustriesSiteDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(industriesSite.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={industriesSite.metaData}
        breadcrumbs={industriesSite.breadcrumbs}
        stickedForm={industriesSite.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={industriesSite.langPath}
      >
        <PageComponents
          components={industriesSite.components}
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default IndustriesSite;
